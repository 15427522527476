<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
========================================================================================== -->

<template>
  <div id="pageView" class="data-list-container">

    <vs-alert v-if="error" class="h-auto mb-4" border color='danger'>
      {{error}}
    </vs-alert>

    <vx-card v-if="!!venue.commercial_record_image || !!venue.company_name || !!venue.vat_number" class="my-5">
        <div class="vx-row">
          <div v-if="!!venue.company_name" class="vx-col w-1/2">
            {{$t('contract.company_name')}}: <h4 style="display:inline">{{ venue.company_name }}</h4>
          </div>

          <div v-if="!!venue.vat_number" class="vx-col w-1/2">
            {{$t('contract.vat_number')}}: <h4 style="display:inline">{{ venue.vat_number }}</h4>
          </div>
        </div>

        <vs-divider></vs-divider>
        <div v-if="!!venue.commercial_record_image" class="vx-row flex justify-center">
          <img :src="baseURL+'/'+venue.commercial_record_image" style="max-height: 300px">
        </div>
    </vx-card>

      <vx-card class="my-5" v-if="agentContract != 'null'">
        <component :is="scrollbarTag" class="scroll-area max-h-250" :settings="settings" :key="$vs.rtl" dir="ltr">
          <vue-markdown class="px-4 " dir="rtl">{{agentContract}}</vue-markdown>
        </component>
      </vx-card>

      <!-- Percentage Section -->
      <vx-card v-if="percentage != 'null'">
        <p>
            {{ ($t('contract.percentage')).replace( ':percentage:', percentage) }}
        </p>
      </vx-card>

      
      <!-- Signing Checkbox -->
      <div class="vx-col w-full md:mb-2 mt-2" v-if="agentContract != 'null' && percentage != 'null'">
        <div class="m-0 flex flex-col text-left items-start">
          
          <div class="flex flex-col">
            <vs-checkbox v-model="is_approved" name="is_separated" class="mt-2">{{$t('contract.applyCheck')}}</vs-checkbox>
          </div>
          <p class="mt-2 ml-6 pl-2">{{$t('contract.message')}}</p>
        </div>
      </div>

      <!-- submit button -->
      <div class="vx-col w-full md:mt-8">
        <vs-button @click="sendApprovedSubmission" v-if="agentContract != 'null' && percentage != 'null'">{{$t('contract.apply')}}</vs-button>
        <p v-else class="text-danger">{{$t('contract.noContract')}}</p>
      </div>
  </div>
</template>

<script>

// import moduleDataList from '@/store/auth/moduleDataList.js'
import VueMarkdown from 'vue-markdown'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import axios from '@/axios'

export default {
  components: {
    VueMarkdown,
    VuePerfectScrollbar
  },
  data () {
    return {
      error: null,
      is_approved:false,
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      venue: {}
    }
  },
  computed: {
    currentVenueId () {
        return this.$store.getters.currentVenueId
    },
    agentContract(){
      return this.$store.getters.contract;
    },
    percentage () {
      return this.$store.getters.percentage;
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag;
    },
    baseURL: () => process.env.VUE_APP_IMAGES_BASE_URL
  },
  methods: {
    sendApprovedSubmission(){
      if(this.is_approved !== true){
        this.error = "يجب الموافقة على الشروط عن طريق الضغط على زر الموافقة على الشروط أدناه";
        return;
      }
      this.error = null;
      this.$vs.loading()
      this.$store.dispatch('auth/signContract', this.currentVenueId)
      .then((response) => {
        this.$router.push('/');
        this.$vs.loading.close()
      })
      .catch((error) => {
        this.$vs.loading.close()
        this.error = error;
      })
   },
   getVenueDetails()
   {
     axios.get(`/venues/${this.currentVenueId}/details`)
      .then((response) => {         
        this.venue = response.data
        console.log(response.data);
        this.$store.dispatch('loader/loaderOff')
      })
      .catch((error)   => { console.log(error) })
   }
  },
  created () {
    this.getVenueDetails()
  },
  mounted () {
    this.isMounted = true
  }
}
</script>

<style lang="scss">
#pageView {
  ul{
    list-style: disc;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  // .bg-secoundy{
  //   background: #10163a9e;
  // }
  // .border-secoundy{
  //   border: 1px solid #10163aa8;
  // }
  .border-raduis{
    border-radius: 1rem;
  }
  .box{
    border-radius: 1rem;
  }
  .max-h-250{
    max-height: 250px;
    // overflow-y: auto;
  }
}
</style>
